export default function useSendEmail() {
  const sendEmail = async ({
    sender,
    recipient,
    subject,
    template,
    canPush,
  }) => {
    const rawResponse = await fetch("/api/mail-service/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sender, recipient, subject, template, canPush }),
    });
    return await rawResponse.json();
  };

  const sendBookingEmail = async ({ booking, template }) => {
    const rawResponse = await fetch("/api/mail-service/send-booking", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ booking, template }),
    });
    return await rawResponse.json();
  };

  const sendCheckoutEmail = async ({ booking, template }) => {
    const rawResponse = await fetch("/api/mail-service/send-booking-checkout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ booking, template }),
    });
    return await rawResponse.json();
  };

  const sendPayoutEmail = async ({ booking, template }) => {
    const rawResponse = await fetch("/api/mail-service/send-booking-payout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ booking, template }),
    });
    return await rawResponse.json();
  };

  const sendCashoutEmail = async ({ booking, template }) => {
    const rawResponse = await fetch("/api/mail-service/send-booking-cashout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ booking, template }),
    });
    return await rawResponse.json();
  };

  const sendMultiEmail = async ({
    body,
    recipients,
    subject,
    template,
    hasPush,
  }) => {
    const rawResponse = await fetch("/api/mail-service/send-multi", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body, recipients, subject, template, hasPush }),
    });
    return await rawResponse.json();
  };

  const sendSupportEmail = async ({ subject, message }) => {
    const rawResponse = await fetch("/api/mail-service/send-support", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subject, message }),
    });
    return await rawResponse.json();
  };

  return {
    sendEmail,
    sendBookingEmail,
    sendMultiEmail,
    sendSupportEmail,
    sendCheckoutEmail,
    sendCashoutEmail,
    sendPayoutEmail,
  };
}
