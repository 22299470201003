import { useState, useMemo, memo, useCallback } from "react";
import {
  Button,
  Center,
  Spinner,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import AboutCard from "components/AboutCard";
import ServicesCard from "components/ServicesCard";
import PhotosCard from "components/PhotosCard";
import ProfileCard from "components/ProfileCard";
import ListHeader from "components/ListHeader";
import { ADMIN_USER, ANDROID, CLIENT, IOS, PROVIDER } from "utils/constants";
import AppDownloadButton from "./AppDownloadButton";
import DirectMessageForm from "./DirectMessageForm";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import ContactCard from "./ContactCard";
import useSettings from "hooks/useSettings";
import DocumentCard from "./DocumentCard";
import { getProfile } from "hooks/useProfile";
import { useEffect } from "react";
import ProviderExpertiseCard from "./ProviderExpertiseCard";

const Profile = ({ profile }) => {
  const user = useUser();
  const supabaseClient = useSupabaseClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [recipient, setRecipient] = useState({});
  const { settings } = useSettings(profile);
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);

  const getUserProfile = useCallback(() => {
    getProfile(supabaseClient, user.id)
      .then((currentUserProfile) => {
        setCurrentUser({
          id: user.id,
          email: user.email,
          firstname: currentUserProfile.firstname,
          account: currentUserProfile.account,
        });
      })
      .finally(() => {
        setLoadingCurrentUser(false);
      });
  }, [supabaseClient, user]);

  useEffect(() => {
    if (user) {
      getUserProfile();
    } else {
      setLoadingCurrentUser(false);
    }
  }, [user, getUserProfile]);

  const onSendMessage = (recipient) => {
    setRecipient(recipient);
    onOpen();
  };
  return (
    <>
      <Center>
        <ProfileCard data={profile} hasAction={false} bg="white" />
      </Center>
      <Center>
        {loadingCurrentUser ? (
          <Spinner />
        ) : currentUser ? (
          <ContactCard
            settings={settings}
            recipient={profile}
            onSendMessage={onSendMessage}
          />
        ) : (
          <Stack spacing={6} direction={"row"}>
            <Button
              as="a"
              id="btn-hero-signup"
              href="/login"
              rounded={"full"}
              px={6}
              colorScheme={"black"}
              bg={"black"}
              _hover={{ bg: "gray.400", color: "black" }}
            >
              {`LOG IN TO CONTACT ${profile.firstname.toUpperCase()}`}
            </Button>
          </Stack>
        )}
      </Center>
      {profile ? (
        <Stack textAlign={"center"} maxW={"2xl"} mx={"auto"}>
          {profile.about ? <AboutCard profile={profile} /> : null}
          {currentUser?.account === ADMIN_USER ? (
            <DocumentCard profile={profile} />
          ) : null}
          {profile.expertise_area ? (
            <ProviderExpertiseCard profile={profile} />
          ) : null}
          <ServicesCard profile={profile} />
          <PhotosCard profile={profile} />
        </Stack>
      ) : null}
      <Stack mt={4}>
        <Center>
          <ListHeader>Install WellFitly App</ListHeader>
        </Center>
        <Center>
          <AppDownloadButton store={IOS} />
        </Center>
        <Center>
          <AppDownloadButton store={ANDROID} />
        </Center>
      </Stack>
      <DirectMessageForm
        recipient={recipient}
        sender={currentUser}
        title={"Direct Message"}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
export default memo(Profile);
