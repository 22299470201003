import { useEffect, memo } from "react";
import debounce from "lodash.debounce";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import Profile from "components/Profile";

const ProfileModal = ({ profile, onClose, isOpen }) => {
  const { slug, firstname } = profile;

  const sendAnalytics = debounce((slug) => {
    const data = {
      slug,
    };
    fetch(`/api/pro/analytics/${slug}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }, 500);
  useEffect(() => {
    if (slug) {
      sendAnalytics(slug);
    }
  }, [slug, sendAnalytics]);
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      {profile ? (
        <ModalContent>
          <ModalHeader>{firstname}</ModalHeader>
          <ModalCloseButton bg="white" color="black" />
          <ModalBody>
            <Profile profile={profile} />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              fontSize={"sm"}
              rounded={"full"}
              bg="black"
              color="white"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : null}
    </Modal>
  );
};

export default memo(ProfileModal);
