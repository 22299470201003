import { useState, useEffect, useMemo } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { COLLECTIONS, FIELDS } from "utils/constants";

const getSettings = async (supabaseClient, userId) => {
  const { data, error } = await supabaseClient
    .from(COLLECTIONS.users_settings)
    .select("*")
    .eq(FIELDS.id, userId);

  if (error) throw new Error(`Failed to get user settings: ${error.message}`);

  if (!error && data.length > 0) {
    return data[0];
  }
};

const useSettings = (user) => {
  const supabaseClient = useSupabaseClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState({});

  const updateSettings = async (payload) => {
    setIsSaving(true);

    try {
      setIsLoading(true);

      const latest = await getSettings(supabaseClient, user.id);
      const data = { ...latest, ...payload };

      await supabaseClient.from(COLLECTIONS.users_settings).upsert(data);

      setSettings((prev) => ({ ...prev, ...payload }));
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      throw new Error(`Failed to update user settings: ${error.message}`);
    }
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true);

      getSettings(supabaseClient, user.id)
        .then((data) => {
          setSettings(data);
          setIsLoading(false);
        })
        .catch((error) =>
          setError(`Failed to load user settings: ${error.message}`)
        );
    }
  }, [supabaseClient, user]);

  const memoizedValue = useMemo(
    () => ({ settings, isLoading, isSaving, error }),
    [settings, isLoading, isSaving, error]
  );
  return { ...memoizedValue, updateSettings };
};

export default useSettings;
