import { memo } from "react";
import { Textarea, FormControl, FormLabel } from "@chakra-ui/react";
import ErrorText from "./shared/typography/ErrorText";

const StyledTextArea = ({
  id,
  name = "textarea",
  value,
  placeholder,
  label,
  register = () => {},
  errors = {},
  ...rest
}) => {
  return (
    <FormControl id={id}>
      {label ? <FormLabel mt={4} mb={2}>{label}</FormLabel> : null}
      <Textarea
        {...rest}
        placeholder={placeholder}
        bg={"gray.100"}
        border={0}
        color={"gray.500"}
        _placeholder={{
          color: "gray.500",
        }}
        errorBorderColor="red.300"
        {...register(name, { value })}
      />
      {errors[name] && <ErrorText text={errors[name].message} />}
    </FormControl>
  );
};

export default memo(StyledTextArea);
