import { useState, useCallback, memo } from "react";
const {
  Box,
  Button,
  Stack,
  Avatar,
  Text,
  useToast,
} = require("@chakra-ui/react");
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { supabase } from "utils/supabase";
import { COLLECTIONS } from "utils/constants";
import StyledTextArea from "./StyledTextArea";
import ModalContainer from "./ModalContainer";
import { FiSend } from "react-icons/fi";
import useSendEmail from "hooks/useSendEmail";

const formSchema = yup.object().shape({
  body: yup.string().max(400).trim().required(),
});

function DirectMessageForm ({ sender, recipient, title, isOpen, onClose }) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const { sendEmail } = useSendEmail();

  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;

  const successToast = useCallback(() => {
    toast({
      title: "Direct Message",
      description: "Message sent successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  },[toast]);

  const errorToast = useCallback((error) => {
    toast({
      title: "Direct Message",
      description: `Could not send message. Reason: ${error.message}`,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  },[toast]);

  const sendDirectMessage = useCallback(async (payload) => {
    try {
      return await supabase.from(COLLECTIONS.inbox).insert([payload]);
    } catch (error) {
      throw error;
    }
  },[]);

  const onSubmitForm = useCallback(
    async ({ body }) => {
      setLoading(true);
      try {
        const payload = {
          body,
          sender: sender.id,
          recipient: recipient.id,
        };
        await sendDirectMessage(payload);
        await sendEmail({
          sender,
          recipient,
          title: `Re: New Message from ${sender.firstname}`,
          canPush: true,
        });
        successToast();
        reset();
        onClose();
      } catch (error) {
        errorToast(error);
      } finally {
        setLoading(false);
      }
    },
    [sender, recipient, onClose, reset, sendEmail, errorToast, successToast, sendDirectMessage]
  );

  return (
    <ModalContainer title={title} isOpen={isOpen} onClose={onClose}>
      <Stack alignItems={"center"}>
        <Box w="full" as={"form"} onSubmit={handleSubmit(onSubmitForm)}>
          <Stack mb={6} direction={"row"} spacing={4} align={"center"}>
            <Avatar src={recipient.avatar} alt={recipient.firstname} />
            <Stack direction={"column"} spacing={0} fontSize={"sm"}>
              <Text fontWeight={600}>{recipient.firstname}</Text>
              <Text fontWeight={300}>{recipient.expertise}</Text>
            </Stack>
          </Stack>
          <Stack spacing={4} pb={4}>
            <StyledTextArea
              id="body"
              name="body"
              placeholder="Write your message (400 characters max)"
              isInvalid={errors.body}
              errorBorderColor="red.300"
              register={register}
              errors={errors}
            />
            <Button
              id="btn-send-message"
              type="submit"
              disabled={isLoading}
              rounded={"full"}
              px={6}
              colorScheme={"black"}
              leftIcon={<FiSend />}
              bg={"black"}
              _hover={{ bg: "gray.400", color: "black" }}
            >
              SEND
            </Button>
          </Stack>
        </Box>
      </Stack>
    </ModalContainer>
  );
};

export default memo(DirectMessageForm);
