import { memo } from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import AboutSocialCard from "./AboutSocialCard";
import { toTitleCase } from "utils/textFormatter";

const AboutCard = ({ profile }) => {
  const firstname = toTitleCase(profile.firstname);
  return (
    <Box p={6}>
      <Text
        color={"gray.500"}
        textTransform={"uppercase"}
        fontWeight={800}
        fontSize={"3xl"}
        fontFamily={"YesevaOne"}
        letterSpacing={1.1}
      >
        About {firstname}
      </Text>
      <Text color={"black.500"}>{profile.about}</Text>
      {profile.instagram ? (
        <Center mt={6}>
          <AboutSocialCard profile={profile} firstname={firstname} />
        </Center>
      ) : null}
    </Box>
  );
};

export default memo(AboutCard);
