import { memo } from "react";
import { IconButton, Stack } from "@chakra-ui/react";
import { FiMail, FiPhoneCall } from "react-icons/fi";

const ContactCard = ({ settings, recipient, onSendMessage, children }) => {
  return (
    <Stack direction={"row"} spacing={2} fontSize={"sm"}>
      {settings?.messaging ? (
        <IconButton
          onClick={() => onSendMessage(recipient)}
          variant="solid"
          bg={"black"}
          color={"white"}
          aria-label="send message"
          icon={<FiMail />}
        />
      ) : null}
      {settings?.calling ? (
        <IconButton
          onClick={() => window.open(`tel: ${recipient.mobile}`)}
          variant="solid"
          bg={"black"}
          color={"white"}
          aria-label="call user"
          icon={<FiPhoneCall />}
        />
      ) : null}
      {children}
    </Stack>
  );
};

export default memo(ContactCard);
