import { memo } from "react";
import { Box, CircularProgress, Image, Stack, Text } from "@chakra-ui/react";
import useApi from "hooks/useApi";
import { isPhotoExtension } from "utils/helpers";
import StyledButton from "./StyledButton";

const DocumentCard = ({ profile }) => {
  const apiUrl = "/api/item/provider-document";
  const url = `${apiUrl}?provider=${profile.id}`;

  const {
    data: { result },
    error,
    loading,
  } = useApi(url);

  return (
    <>
      {error && (
        <Stack align={"center"}>
          <Text as="h2" color={"red.500"} fontSize="lg">
            {error}
          </Text>
        </Stack>
      )}
      {loading && (
        <Stack align={"center"} mt={20}>
          <CircularProgress mx={"auto"} isIndeterminate color="black" />
        </Stack>
      )}

      {result ? (
        <Box py={6} px={2}>
          <Text
            color={"gray.500"}
            textTransform={"uppercase"}
            fontWeight={800}
            fontSize={"md"}
            fontFamily={"YesevaOne"}
            letterSpacing={1.1}
            marginBottom={5}
          >
            {profile.firstname}&apos;s Document
          </Text>
          {isPhotoExtension(result.media_url) ? (
            <Image
              width="100%"
              src={result.media_url}
              alt={profile.firstname}
              rounded="lg"
              objectFit={"contain"}
              shadow="lg"
              mx="auto"
              fallbackSrc="/photo-placeholder-350x350.png"
            />
          ) : (
            <StyledButton
              onClick={() => window.open(result.media_url, "_blank")}
              text={"View Document"}
            >
              View Document
            </StyledButton>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default memo(DocumentCard);
