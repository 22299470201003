import { memo } from "react";
import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
} from "@chakra-ui/react";
import { FaUser, FaCheckCircle } from "react-icons/fa";
import { timeAgo } from "utils/helpers";
import { FiMapPin } from "react-icons/fi";
import { toTitleCase } from "utils/textFormatter";

function ProfileCard({ data, onSelect, hasAction, bg = "#F9F9F9" }) {
  const {
    firstname,
    avatar,
    expertise,
    city,
    state,
    location,
    verified,
    last_login,
  } = data;
  return (
    <Center>
      <Box
        maxW={"320px"}
        w={"full"}
        bg={bg}
        boxShadow={bg == "#F9F9F9" ? "md" : null}
        rounded={"lg"}
        p={hasAction ? 6 : 2}
        textAlign={"center"}
        py={6}
      >
        <Avatar
          size={"xl"}
          src={avatar}
          alt={`${firstname}'s Avatar`}
          mb={4}
          pos={"relative"}
        />
        <Stack
          mt={8}
          mx={"auto"}
          direction={"row"}
          spacing={2}
          align="center"
          justify="center"
        >
          <Heading fontSize={"lg"} fontFamily={"YesevaOne"}>
            {toTitleCase(firstname)}
          </Heading>
          {verified ? <FaCheckCircle /> : null}
        </Stack>
        <Text color={"gray.500"}>{expertise || "Fitness Pro"}</Text>
        <Center>
          <Box display={"flex"} alignItems={"center"} mx={"auto"}>
            <FiMapPin />
            <Text
              marginLeft={1}
              fontSize={"sm"}
              color={"gray.700"}
              casing={"uppercase"}
            >
              {city && state
                ? `${city}, ${state}`
                : location || state || city || "The Gym"}
            </Text>
          </Box>
        </Center>
        {hasAction && (
          <Stack mt={8} direction={"column"} spacing={1}>
            <Button
              mx="auto"
              onClick={() => onSelect(data)}
              fontSize={"sm"}
              rounded={"full"}
              leftIcon={<FaUser />}
              _focus={{
                bg: "gray.200",
              }}
            >
              View
            </Button>
            <Text fontSize={"sm"} color={"gray.700"}>
              Last seen {last_login ? timeAgo(last_login) : "yesterday"}
            </Text>
          </Stack>
        )}
      </Box>
    </Center>
  );
}

export default memo(ProfileCard);
